<template>
    <v-flex xs12 sm12 md12>
        <div v-if="loading" v-bind:style="loadingStyle">
        </div>
        <v-progress-circular v-if="loading" indeterminate v-bind:style="loadingSpinner"></v-progress-circular>
        <v-tabs color="deep-purple accent-4" fixed-tabs>

            <v-tab v-for="(item,index) in tabs" :key="index">
                <span>{{item.tab}}</span>
            </v-tab>

            <v-tab-item>
                <p class="text-h4 text--primary">
                    {{tabs[0].title}}
                </p>

                <editor_ca v-if="tabs[0].text != null" ref="editor_ca"
                    api-key="098e2lg9ndghp2biwixep6nvijjvrvey515t8ow4bp0ehsnf" :init="{
                      height: 500,
                      width:900,
                      menubar: false,
                      plugins: 'table',
                      inline: true,
                      table_responsive_width: true,
                      table_sizing_mode: 'responsive',
                      table_column_resizing: 'resizetable',
                      table_grid: false,
                      table_use_colgroups: false,
                    }" :force_br_newlines=true :force_p_newlines=false :value=tabs[0].text />

                <v-btn type="submit" @click="process" class="mt-4" color="primary" value="Process">
                    Processar
                </v-btn>

            </v-tab-item>
            <v-tab-item>
                <p class="text-h4 text--primary">
                    {{tabs[1].title}}
                </p>

                <editor_es v-if="tabs[1].text != null" ref="editor_es"
                    api-key="098e2lg9ndghp2biwixep6nvijjvrvey515t8ow4bp0ehsnf" :init="{
                      height: 500,
                      width:900,
                      menubar: false,
                      plugins: 'table',
                      inline: true,
                      table_responsive_width: true,
                      table_sizing_mode: 'responsive',
                      table_column_resizing: 'resizetable',
                      table_grid: false,
                      table_use_colgroups: false,
                    }" :force_br_newlines=true :force_p_newlines=false :value=tabs[1].text />

                <v-btn type="submit" @click="process" class="mt-4" color="primary" value="Process">
                    Processar
                </v-btn>

            </v-tab-item>
            <v-tab-item>
                <p class="text-h4 text--primary">
                    {{tabs[2].title}}
                </p>

                <editor_en v-if="tabs[2].text != null" ref="editor_en"
                    api-key="098e2lg9ndghp2biwixep6nvijjvrvey515t8ow4bp0ehsnf" :init="{
                      height: 500,
                      width:900,
                      menubar: false,
                      plugins: 'table',
                      inline: true,
                      table_responsive_width: true,
                      table_sizing_mode: 'responsive',
                      table_column_resizing: 'resizetable',
                      table_grid: false,
                      table_use_colgroups: false,
                    }" :force_br_newlines=true :force_p_newlines=false :value=tabs[2].text />

                <v-btn type="submit" @click="process" class="mt-4" color="primary" value="Process">
                    Processar
                </v-btn>

            </v-tab-item>
        </v-tabs>

    </v-flex>

</template>

<script>
import { get_content, set_content } from '../services/watchity'
import Editor from '@tinymce/tinymce-vue'
import axios from 'axios';
export default {
    data: () => {
        return {
            tabs: [
                {
                    tab: 'CAT',
                    title: 'Català',
                    text: null,
                    meta: 'subtitles_ca',
                    ref: 'editor_ca',
                    lang: 'ca',
                    uuid: null
                },
                {
                    tab: 'ESP',
                    title: 'Español',
                    text: null,
                    meta: 'subtitles_es',
                    ref: 'editor_es',
                    lang: 'es',
                    uuid: null
                },
                {
                    tab: 'ENG',
                    title: 'English',
                    text: null,
                    meta: 'subtitles_en',
                    ref: 'editor_en',
                    lang: 'en',
                    uuid: null
                },
            ],
            isAuth: false,
            subtitles: '',
            loading: false,
            loadingStyle: {
                position: 'fixed', top: '0', left: '0', width: '100vw', height: '100%', 'background-color': 'black', opacity: 0.5, 'z-index': 30,
            },
            loadingSpinner: {
                position: 'fixed', top: '50%', left: '50%', 'z-index': 31, width: '45px', height: '45px'
            }
        }
    },
    methods: {
        async process() {

            this.loading = true
            await new Promise((resolve) => setTimeout(resolve, 500))
            try {
                await Promise.all(this.tabs.forEach(async tab => {

                    if (this.$refs && tab.ref in this.$refs) {
                        await set_content(this.table2srt(this.$refs[tab.ref].element.firstChild.firstChild.children), this.$route.params.media, tab.uuid, tab.lang, this.$route.params.id)
                    }
                }))
            }
            catch (err) {
                console.error('Hi ha hagut un problema a l\'hora de processar les dades. Si us plau, intenta-ho de nou en una estona.', err)
            }
            finally {
                this.loading = false
            }

        },
        srt2table(str, lang) {
            const header = (() => {
                switch (lang) {
                    case 'ca':
                        return (`
                        <tr style="border: 1px solid black; margin:0">
                            <th style="border: 1px solid black; padding: 5px">
                                Inici
                            </th>
                            <th style="border: 1px solid black; padding: 5px">
                                Fi
                            </th>
                            <th style="border: 1px solid black; padding: 5px">
                                Línia 1
                            </th>
                            <th style="border: 1px solid black; padding: 5px">
                                Línia 2
                            </th>
                            <th style="border: 1px solid black; padding: 5px">
                                Línia 3
                            </th>
                        </tr>
                        `)
                    case 'es':
                        return (`
                        <tr style="border: 1px solid black; margin:0">
                            <th style="border: 1px solid black; padding: 5px">
                                Inicio
                            </th>
                            <th style="border: 1px solid black; padding: 5px">
                                Fin
                            </th>
                            <th style="border: 1px solid black; padding: 5px">
                                Línea 1
                            </th>
                            <th style="border: 1px solid black; padding: 5px">
                                Línea 2
                            </th>
                            <th style="border: 1px solid black; padding: 5px">
                                Línea 3
                            </th>
                        </tr>
                        `)
                    case 'en':
                        return (`
                        <tr style="border: 1px solid black; margin:0">
                            <th style="border: 1px solid black; padding: 5px">
                                Start
                            </th>
                            <th style="border: 1px solid black; padding: 5px">
                                End
                            </th>
                            <th style="border: 1px solid black; padding: 5px">
                                Line 1
                            </th>
                            <th style="border: 1px solid black; padding: 5px">
                                Line 2
                            </th>
                            <th style="border: 1px solid black; padding: 5px">
                                Line 3
                            </th>
                        </tr>
                        `)
                }

            }).call()
            return (
                `<table style="margin-right: auto; margin-left: auto;border: 1px solid black;  border-collapse: collapse;"><tbody>${header}${str.split('\n\n').slice(0, str.split('\n\n').length).map(item =>
                    `<tr style="border: 1px solid black; margin:0">${item.split('\n').map((it, index) => {
                        if (index == 0 || index >= 3) return null
                        else if (index == 1 && it.split(' --> ').length > 1)
                            return (`<td style="border: 1px solid black; padding: 5px">${it.split(' --> ')[0]}</td><td style="border: 1px solid black; padding: 5px">${it.split(' --> ')[1]}</td>`)
                        else{
                            const lines = item.split('\n').slice(2)
                            return (`
                                <td style="border: 1px solid black; padding: 5px">${lines[0]}</td>
                                <td style="border: 1px solid black; padding: 5px">${lines[1] ? lines[1] : ''}</td>
                                <td style="border: 1px solid black; padding: 5px">${lines[2] ? lines[2] : ''}</td>
                            `)
                        }
                    }
                    ).join('')}</tr>`
                ).join('')}</tbody></table>
            `)
        },
        table2srt(str) {

            const arr = [].slice.call(str);
            const result = arr.slice(1).map((row, index) => {
                const children = [].slice.call(row.children)
                if (children.length > 0) {
                    var regExp = /[a-zA-Z]/g;
                    return `${index + 1}\n${children[0].textContent} --> ${children[1].textContent}\n${children.slice(2).map(child => regExp.test(child.textContent) ? child.textContent + '\n' : null).join('')}`
                }
                return null

            }).join('\n')
            const last = result.lastIndexOf('\n')

            return result.slice(0, last);
        },
        async get_subtitles() {

            const response = await get_content(this.$route.params.media, this.$route.params.id)
            await response.data.results.forEach(async object => {
                const data = await axios.get(object.content)
                this.tabs.find(tab => tab.lang == object.lang).text = this.srt2table(data.data, object.lang)
                this.tabs.find(tab => tab.lang == object.lang).uuid = object.uuid
            })
        }
    },
    async created() {


    },
    mounted() {

        this.get_subtitles()

    },
    components: {
        editor_ca: Editor,
        editor_es: Editor,
        editor_en: Editor
    }
};
</script>