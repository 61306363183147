const axios = require("axios").default;
const fetch = require("node-fetch");

export const get_content = (mediateca_uuid, uuid) => axios.get(`${process.env.VUE_APP_URL_WATCHITY}/mediatecas/${mediateca_uuid}/media/${uuid}/children/?type=subtitles`,
  {
    auth: {
      username: process.env.VUE_APP_USER,
      password: process.env.VUE_APP_PASSWORD
    }
  })



export const set_content = async (trans, mediateca_uuid, uuid, lang, father_uuid) => {
  return new Promise((resolve, reject) => {
    
    try {
      const form = new FormData();
      form.append('content', new Blob([trans]), `${father_uuid}_${lang}.srt`);
      form.append('type', 'subtitles');
      const headers = new fetch.Headers();
      headers.set('Authorization', `Basic ${Buffer.from(process.env.VUE_APP_USER + ":" + process.env.VUE_APP_PASSWORD).toString('base64')}`)
      fetch(`${process.env.VUE_APP_URL_WATCHITY}/mediatecas/${mediateca_uuid}/media/${uuid}/`,
        {
          body: form,
          method: "PATCH",
          headers: headers,
        }).then((response) => {
          resolve(response)
        })
    }
    catch (err) {
      reject(err)
    }
  })

}


